'use client';

import React, { useEffect } from 'react';

import { getCards } from 'src/api/card';
import { getBasket } from 'src/api/basket';
import { useAuthContext } from 'src/auth/hooks';
import { useAppContext } from 'src/contexts/AppContext';
import { getActivityByDomain } from 'src/api/activities';

import { getVirtualCards } from '../../api/clubCards';

const BasicDownload = () => {
  const { dispatch, state } = useAppContext();
  const { user } = useAuthContext();

  useEffect(() => {
    const getBasic = async () => {
      await getActivityByDomain(dispatch);

      if (user) {
        await getVirtualCards(dispatch);
        await getBasket(state.smbAccount.id, dispatch);
        await getCards(dispatch);
      }
    };
    getBasic();
  }, [user]);

  return <div />;
};
export default BasicDownload;
